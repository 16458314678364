<template>
  <div class="zee-container">
    <zee-toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <zee-screen-title
      :title="'Enter Details'"
      :route="'/solutions/zeetv/program-launch'"
    />

    <div class="zee-card-background">
      <div class="zee-multi-tabs-wrapper">
        <zee-tabs title="Campaign Launch Date" icon="calendar">
          <div class="d-flex">
            <zee-calendar
              :placeHolder="'Select'"
              :labelText="'Date'"
              @getDate="getDateInput($event)"
            />
            <div class="ml-3 d-flex align-items-center fs-5 font-weight-bold">
              selected date: {{ this.formatedDate }}
            </div>
          </div>
        </zee-tabs>
        <zee-tabs title="Number of Weeks" icon="chevron-right">
          <zee-dropdown
            :options="numOfWeeksDropdown"
            :selectedOption="selectedWeek"
            @input="handleWeekInput($event)"
            class="mr-3"
          />
        </zee-tabs>

        <div class="text-center font-weight-bold" style="font-size: 16px">
          <!-- Your selected number of week : {{ selectedWeek.text }} -->
        </div>

        <!-- <zee-tabs title="Plan Name" icon="chevron-right">
          <zee-input @onChange="getProgramInput" />
          <div class="fs-5 d-flex align-items-center" style="color: red" v-if="programAlreadyExists">
              Program name already exist, please try diffrent name
            </div>
        </zee-tabs> -->
      </div>
      <!-- <div>{{selectedDays}}</div> -->
      <div class="zee-program-save-button">
        <zee-button
          @onClick="handleNextReq"
          title="Next"
          :icon="'save'"
          :isDisable="false"
        ></zee-button>
        <!-- <zee-button
          class="d-none"
          style="background-color: #000"
          @onClick="goNext"
          title="Next"
          :icon="'arrow-right'"
          :isDisable="false"
        ></zee-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, Tabs, Button, Dropdown, ScreenTitle } from "../components";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();
export default {
  name: "LaunchDetails",
  components: {
    "zee-button": Button,
    "zee-tabs": Tabs,
    "zee-calendar": Calendar,
    "zee-dropdown": Dropdown,
    // "zee-input": Input,
    "zee-screen-title": ScreenTitle,
  },

  data() {
    return {
      selectedWeek: {},
      selectedDate: "",
      userInput: "",
      numOfWeeksDropdown: [],
      isWeekSelected: false,
      isDateSelected: false,
      isPlanSelected: false,
      inputValue: "",
      selectedDay: "",
      formatedRuntime: "",
      isValueSubmitted: false,
      selectedGenre: {},
      formatedDate: "",
      selectedFrequency: "",
      GenreDropdown: [
        { text: "Fiction", id: 0 },
        { text: "Non Fiction", id: 1 },
        { text: "WTP", id: 2 },
        { text: "Events", id: 3 },
      ],
      FrequencyDropdown: [
        {
          id: 1,
          name: "Monday",
          value: false,
          $isDisabled: false,
          key: "Monday",
        },
        {
          id: 2,
          name: "Tuesday",
          value: false,
          $isDisabled: false,
          key: "Tuesday",
        },
        {
          id: 3,
          name: "Wednesday",
          value: false,
          $isDisabled: false,
          key: "Wednesday",
        },
        {
          id: 4,
          name: "Thursday",
          value: false,
          $isDisabled: false,
          key: "Thursday",
        },
        {
          id: 5,
          name: "Friday",
          value: false,
          $isDisabled: false,
          key: "Friday",
        },
        {
          id: 6,
          name: "Saturday",
          value: false,
          $isDisabled: false,
          key: "Saturday",
        },
        {
          id: 0,
          name: "Sunday",
          value: false,
          $isDisabled: false,
          key: "Sunday",
        },
      ],
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      programDetails: {},
      minRuntime: "",
      maxRuntime: "",
      programFrequency: [],
      selectedDays: [],
    };
  },
  created() {
    this.retriveData();
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    // getSummaryTableData(e) {
    //   this.isLoading = true;
    //   let clientId = sessionStorage.getItem("subId");
    //   zeeSolutions
    //     .outputSummaries(clientId)
    //     .then((res) => {
    //       let data = res.filter((x) => x.programName === e);
    //       data.length > 0
    //         ? ((this.toastData = {
    //           show: true,
    //           message: "Program name already exist, please give diffrent name",
    //           type: "info",
    //         }),
    //           (this.programAlreadyExists = true))
    //         : (this.programAlreadyExists = false);
    //       this.isLoading = false;
    //     })
    //     .catch((err) => {
    //       this.isLoading = false;
    //       alert(err);
    //     });
    // },
    // getProgramInput(e) {
    //   this.userInput = e;
    //   e.trim() !== "" ? (this.isPlanSelected = true) : (this.isPlanSelected = false);
    //   this.getSummaryTableData(e)
    //   // const dataChange = debounce(() => this.getSummaryTableData(e), 2000);
    //   // console.log(dataChange())
    // },
    postUserInput() {
      let creationData = this.formatDate(new Date());

      let data = JSON.parse(localStorage.getItem("programDetails"));
      data[0].programName = this.inputValue;
      data[0].programLaunchDate = this.selectedDate;
      data[0].creationDate = creationData;
      data[0].userEmail = sessionStorage.getItem("clientId");
      data[0].programNumberOfWeeks = this.selectedWeek.id;
      data[0].programTargetGRPs.min = 0;
      data[0].programTargetGRPs.max = 0;
      data[0].secondages = [];
      data[0].planName = this.userInput;
      data[0].programPTPercentage = 0;
      data[0].programName = "";
      localStorage.setItem("programDetails", JSON.stringify(data));

      zeeSolutions
        .postUserInput(...data)
        .then((res) => {
          console.log(res);
          sessionStorage.setItem("run_id", res.runid);
          this.$router.push(`/solutions/zeetv/program-plans`);
        })
        .catch((err) => {
          alert(err);
        });
    },
    getDateInput(e) {
      let date = this.formatDate(e);
      let todaysDate = this.formatDate(new Date());
      this.selectedDate = date;
      this.formatedDate = this.selectedDate
      // console.log(date, todaysDate)
      let x = this.getWeeksDiff(new Date(date), new Date(todaysDate));

      this.numOfWeeksDropdown = [];

      for (var i = 0; i < x; i++) {
        this.numOfWeeksDropdown.push({ id: i + 1, text: i + 1 });
      }
      this.isDateSelected = true;
      this.isWeekSelected = false;
      this.isPlanSelected = false;
      this.selectedWeek = {};
    },

    handleWeekInput(e) {
      this.isWeekSelected = false;
      this.selectedWeek = e;
      console.log(this.selectedWeek);
      if (e.id !== undefined) {
        this.isWeekSelected = true;
      }
    },
    getWeeksDiff(startDate, endDate) {
      const msInWeek = 1000 * 60 * 60 * 24 * 7;

      return Math.round(Math.abs(endDate - startDate) / msInWeek);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    handleNextReq() {
      sessionStorage.setItem("weeks", this.selectedWeek.id);
      sessionStorage.setItem(
        "programLaunchDate",
        JSON.stringify({
          date: this.formatedDate,
          weekNumber: this.selectedWeek.id,
        })
      );
    
      sessionStorage.setItem("show_add_program_screen", true);
      this.postUserInput();
    },
    retriveData () {
      let prevData = JSON.parse(sessionStorage.getItem("programLaunchDate"))
      this.selectedWeek = {id: prevData.weekNumber, text:prevData.weekNumber}
      this.formatedDate = prevData.date
      // this.getDateInput(this.formatDate)
      let todaysDate = this.formatDate(new Date());
      let date = this.formatedDate
       let x = this.getWeeksDiff(new Date(date), new Date(todaysDate));

      this.numOfWeeksDropdown = [];

      for (var i = 0; i < x; i++) {
        this.numOfWeeksDropdown.push({ id: i + 1, text: i + 1 });
      }
      console.log(prevData)
    }
  },
  computed: {
    disabledNextReqBtn() {
      return !this.isDateSelected || !this.isWeekSelected || !this.isPlanSelected;
    },
  },
};
</script>

<style>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  height: 100%;
}

.zee-card-background {
  padding: 3rem;
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  border-radius: 15px;
}
.zee-multi-tabs-wrapper {
  width: 100%;
  gap: 3rem;
}
.zee-program-save-button {
  display: flex;
  justify-content: flex-end;
}
</style>
